<template>
  <div class="flex-grow-1">
    <div>
      <div>
        <v-row>
          <v-col cols="12">
            <h2 class="text-h4 font-weight-black">My Availability</h2>
            <div class="mt-5" flat>
              <custom-alert type="info" border="left" text>
                <p>Use this page to set your availability. Keep it up to date to ensure you get found by employers during those times you want to work, and not get contacted about jobs when you’re unavailable or don’t want to work.  </p>
                <p>To select bulk dates use the quick links provided or simply click the start date on the calendar, hold, drag and lift on the last applicable date then nominate if this period is available/unavailable. (NOTE: the click and drag feature is only available via desktop). </p>
              </custom-alert>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-divider class="mt-3"></v-divider>
    </div>

    <v-row class="fill-height">
      <!-- <v-col cols="3">
          <div>
              <SetAvailability :eventSelectedData="thisEventData" @isModify="setModifyTrigger" @parentAvailability="selectedAvailability"/>
          </div>
        </v-col> -->

      <v-col cols="12">
        <preloader v-if="!available" />
        <div v-if="user && user.role == 'jobseeker'">
          <v-radio-group @change="yearAvailability()" v-model="isYearAvailable.entireYear">
            <v-radio 
              label="Mark me available for the full year" 
              value="full"
              dense
            ></v-radio>
            
            <v-radio 
              label="Mark me available for the next three months" 
              value="months"
              dense
            ></v-radio>
          </v-radio-group>
        </div>
        <v-card class="shadow-lg">
          <v-card-text>
            <v-sheet height="64">
              <v-toolbar flat>
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
                >
                  Today
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="prev">
                  <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="next">
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
              </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
              <v-calendar
                id="availability"
                v-if="events && user"
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                :type="type"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="getDay"
                @change="getEvents"
                @mousedown:day="startDrag"
                @mouseup:day="endDrag"
              ></v-calendar>

              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
              >
                <v-card :color="selectedEvent.color" dark flat>
                  <v-card-text>
                    <div class="d-flex align-center">
                      <v-btn
                        @click="removeEvent(selectedEvent)"
                        v-if="user && user.role == 'jobseeker'"
                        class="mr-3"
                        icon
                      >
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                      <div v-html="selectedEvent.name"></div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <AvailabilityDialog
      :selectedDate="selectedDay"
      :date="choosenDate"
      @close="closeDialogForm"
      @isModify="setModifyTrigger"
    />
    <UpdateAvailabilityDialog
      :selectedDate="updateSelectedDay"
      :events="updateDateEvents"
      @close="closeDialogForm"
      @isModify="setModifyTrigger"
    />
  </div>
</template>

<script>
// import SetAvailability from '@/components/availability/SetAvailability'
import AvailabilityDialog from "@/components/availability/AvailabilityDialog"
import UpdateAvailabilityDialog from "@/components/availability/UpdateAvailabilityDialog"
import _remove from "lodash/remove"
import _find from "lodash/find"
// import moment from 'moment'

import { mapState, mapActions } from "vuex"
export default {
  name: "Availability",

  metaInfo: {
    title: "Availability",
  },

  data() {
    return {
      bulkDates: null,
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
      },
      thisEventData: {
        id: null,
        triggered: false,
      },
      saveDate: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      selectedDay: false,
      updateSelectedDay: false,
      choosenDate: null,
      dragStart: null,
      dragEnd: null,
      updateDateEvents: [],
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "deep-orange",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
    }
  },

  components: {
    // SetAvailability,
    AvailabilityDialog,
    UpdateAvailabilityDialog,
  },
  /* -------------------------------------------------------------------------- */
  /*                                  COMPUTED                                  */
  /* -------------------------------------------------------------------------- */
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      status: (state) => state.availability.status,
      available: (state) => state.availability.userAvailability,
      jobseekerAvailability: (state) => state.availability.setJobSeekerAvailability,
      changedEvents: (state) => state.availability.availability,
      isYearAvailable: (state) => state.availability.isYearAvailable,
    }),
  },
  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  methods: {
    ...mapActions("availability", [
      "getUserAvailability",
      "getJobseekerAvailability",
      "availability",
      "checkAvailableThisYear",
    ]),

    viewDay({ date }) {
      this.focus = date
      // this.type = 'day'
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.focus = ""
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = {}
        this.selectedElement = null
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },

    getDay({ date }) {
      const open = () => {
        this.choosenDate = date
        setTimeout(() => {
          this.selectedDay = true
        }, 10)
      }

      const updateOpen = () => {
        setTimeout(() => {
          this.updateSelectedDay = true
        }, 10)
      }

      let eventCounter = this.events.map((event) => {
        if (event.startDate == date) {
          this.updateDateEvents.push(event)
          return true
        } else {
          return false
        }
      })

      const countEvents = eventCounter.filter(Boolean).length

      if (countEvents < 2) {
        if (this.selectedDay) {
          this.selectedDay = false
          this.choosenDate = null
          setTimeout(open, 10)
        } else {
          open()
        }
      } else {
        if (this.updateSelectedDay) {
          this.updateDateEvents = []
          this.updateSelectedDay = false
          setTimeout(updateOpen, 10)
        } else {
          updateOpen()
        }
      }
    },

    removeEvent(selectedEvent) {
      const updateOpen = () => {
        this.updateDateEvents.push(selectedEvent)
        
        setTimeout(() => {
          this.updateSelectedDay = true
        }, 10)
      }

      if (this.updateSelectedDay) {
        this.updateDateEvents = []
        this.updateSelectedDay = false
        setTimeout(updateOpen, 10)
      } 
      else {
        updateOpen()
      }
    },

    modifyEvent() {
      this.thisEventData = {
        id: null,
        triggered: false,
      }
      this.thisEventData.triggered = true
      this.thisEventData.id = this.selectedEvent.id
      this.thisEventData.startDate = this.selectedEvent.startDate
      this.thisEventData.endDate = this.selectedEvent.endDate
      this.thisEventData.startHr = this.selectedEvent.startHr
      this.thisEventData.startMin = this.selectedEvent.startMin
      this.thisEventData.endHr = this.selectedEvent.endHr
      this.thisEventData.endMin = this.selectedEvent.endMin
    },

    getEvents() {
      const events = []
      const colors = this.colors
      let colorCount = 0
      let AssignedColor = null
      if (this.user && this.user.role == "admin") {
        Promise.all([this.getJobseekerAvailability()]).then(() => {
          if (this.jobseekerAvailability.length) {
            this.jobseekerAvailability.forEach(function (date) {
              let foundEvent = _find(events, function (thisEvent) {
                return date.userid == thisEvent.userid
              })

              if (foundEvent) {
                AssignedColor = foundEvent.color
              } else {
                AssignedColor = colors[colorCount]
              }

              // var startDateTime = moment(date.startDate + ' ' + date.startHr + ':'+ date.startMin, 'YYYY/MM/DD HH:mm')
              // var endDateTime = moment(date.endDate + ' ' + date.endHr + ':'+ date.endMin, 'YYYY/MM/DD HH:mm')

              events.push({
                name: date.display,
                start: date.startDate,
                end: date.endDate,
                id: date.id,
                userid: date.userid,
                startDate: date.startDate,
                startHr: date.startHr,
                startMin: date.startMin,
                endDate: date.endDate,
                endHr: date.endHr,
                endMin: date.endMin,
                color: AssignedColor,
                timed: false,
              })
              if (colorCount > 7) colorCount == 0
              colorCount = colorCount + 1
            })
          }
        })
      } else if (this.user && this.user.role == "jobseeker") {
        Promise.all([this.getUserAvailability()]).then(() => {
          if (this.available.length) {
            this.available.forEach(function (date) {
              events.push({
                name: date.display,
                start: date.startDate,
                end: date.endDate,
                id: date.id,
                userid: date.userid,
                startDate: date.startDate,
                startHr: date.startHr,
                startMin: date.startMin,
                endDate: date.endDate,
                endHr: date.endHr,
                endMin: date.endMin,
                color: date.color,
                timed: false,
              })
            })
          }
        })
      }

      this.events = events
    },

    setModifyTrigger(data) {
      if (data.action == "store") {
        if (this.changedEvents) {
          this.events.push({
            name: this.changedEvents.display,
            start: this.changedEvents.startDate,
            end: this.changedEvents.endDate,
            id: this.changedEvents.id,
            userid: this.changedEvents.userid,
            startDate: this.changedEvents.startDate,
            startHr: this.changedEvents.startHr,
            startMin: this.changedEvents.startMin,
            endDate: this.changedEvents.endDate,
            endHr: this.changedEvents.endHr,
            endMin: this.changedEvents.endMin,
            color: this.changedEvents.color,
            timed: false,
          })
        }
      } else if (data.action == "update") {
        if (this.changedEvents) {
          this.setModifyTrigger({ action: "delete" })

          this.events.push({
            name: this.changedEvents.display,
            start: this.changedEvents.startDate,
            end: this.changedEvents.endDate,
            id: this.changedEvents.id,
            userid: this.changedEvents.userid,
            startDate: this.changedEvents.startDate,
            startHr: this.changedEvents.startHours,
            startMin: this.changedEvents.startMinutes,
            endDate: this.changedEvents.endDate,
            endHr: this.changedEvents.endHours,
            endMin: this.changedEvents.endMinutes,
            color: this.changedEvents.color,
            timed: false,
          })
        }
      } else if (data.action == "delete") {
        let removedEvent = []
        let removedId = this.changedEvents.id
        if (this.changedEvents) {
          removedEvent = _remove(this.events, function (event) {
            return event.id != removedId
          })
        }
        this.events = removedEvent
      } else {
        this.thisEventData.triggered = false
      }

      this.thisEventData.triggered = false
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },

    selectedAvailability(selectedUser) {
      let events = []

      if (selectedUser.user.length != 0) {
        if (this.jobseekerAvailability.length) {
          let eventData = this.jobseekerAvailability.filter((data) => {
            return data.userid == selectedUser.user.userid
          })

          eventData.forEach(function (date) {
            // var startDateTime = moment(date.startDate + ' ' + date.startHr + ':'+ date.startMin, 'YYYY/MM/DD HH:mm')
            // var endDateTime = moment(date.endDate + ' ' + date.endHr + ':'+ date.endMin, 'YYYY/MM/DD HH:mm')

            events.push({
              name: date.display,
              start: date.startDate,
              end: date.endDate,
              id: date.id,
              userid: date.userid,
              startDate: date.startDate,
              startHr: date.startHr,
              startMin: date.startMin,
              endDate: date.endDate,
              endHr: date.endHr,
              endMin: date.endMin,
              color: date.color,
              timed: false,
            })
          })
        }
      } else {
        if (this.jobseekerAvailability.length) {
          const colors = this.colors
          let colorCount = 0
          let AssignedColor = null
          this.jobseekerAvailability.forEach(function (date) {
            let foundEvent = _find(events, function (thisEvent) {
              return date.userid == thisEvent.userid
            })

            if (foundEvent) {
              AssignedColor = foundEvent.color
            } else {
              AssignedColor = colors[colorCount]
            }

            // var startDateTime = moment(date.startDate + ' ' + date.startHr + ':'+ date.startMin, 'YYYY/MM/DD HH:mm')
            // var endDateTime = moment(date.endDate + ' ' + date.endHr + ':'+ date.endMin, 'YYYY/MM/DD HH:mm')

            events.push({
              name: date.display,
              start: date.startDate,
              end: date.endDate,
              id: date.id,
              userid: date.userid,
              startDate: date.startDate,
              startHr: date.startHr,
              startMin: date.startMin,
              endDate: date.endDate,
              endHr: date.endHr,
              endMin: date.endMin,
              color: AssignedColor,
              timed: false,
            })
            if (colorCount > 7) colorCount == 0
            colorCount = colorCount + 1
          })
        }
      }

      this.events = events
    },

    closeDialogForm() {
      this.choosenDate = null
      this.selectedDay = false
      this.updateSelectedDay = false
      this.updateDateEvents = []
    },

    yearAvailability() {
      Promise.resolve(
        this.availability({
          available: this.isYearAvailable,
          action: "store",
        }),
      ).then(() => {
        this.setModifyTrigger({ action: "store" })
      })
      // if (!this.isYearAvailable.id) {
      // } else {
      //   Promise.all([
      //     this.availability({
      //       available: this.isYearAvailable,
      //       action: "delete",
      //     }),
      //   ]).then(() => {
      //     this.setModifyTrigger({ action: "delete" })
      //   })
      // }
    },

    startDrag(date) {
      this.dragStart = date.date
    },

    endDrag(date) {
      if (this.dragStart != date.date) {
        this.dragEnd = date.date
        this.selectedDay = true
        this.choosenDate = this.dragStart + "|" + this.dragEnd
      }
    },
  },

  created() {
    this.checkAvailableThisYear()
  },
}
</script>