<template>
  <div>
    <v-dialog
      :value="selectedDate"
      max-width="350"
      persistent
      no-click-animation
    >
      <v-card>
        <v-card-title>
          <v-icon left>mdi-delete</v-icon>
          <span class="font-weight-bold">Remove Availability</span>
        </v-card-title>
        <v-card-text>
          <v-fade-transition>
            <v-form ref="formAvailability" class="mb-n5">
              <v-row>
                <v-col class="pt-0 pb-0">
                  <span class="heading-font font-weight-bold">Please Select:</span>
                  <v-radio-group
                    class="mt-0"
                    dense
                    :rules="[rules.required]"
                    v-model="eventId"
                  >
                    <v-radio
                      v-for="(event, index) in events"
                      :key="index"
                      :label="trimMsg(event.name)"
                      :value="event.id"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-form>
          </v-fade-transition>

          <div class="mt-9 d-flex gap-7">
            <v-btn
              color="accent"
              class="gradient"
              :loading="status.getting"
              :disabled="status.getting"
              small
              @click="confirmAvailability()"
              >Confirm</v-btn
            >

            <v-btn
              depressed
              small
              :disabled="status.getting"
              @click="closeDialog()"
              >Cancel</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import rules from "@/rules";

export default {
  props: {
    selectedDate: Boolean,
    events: Array,
  },

  data() {
    return {
      rules,
      eventId: null,
    };
  },

  /* -------------------------------------------------------------------------- */
  /*                                  COMPUTED                                  */
  /* -------------------------------------------------------------------------- */
  computed: {
    ...mapState({
      status: (state) => state.availability.status,
    }),
  },
  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  methods: {
    ...mapActions("availability", ["removeAvailability"]),

    convertDate(date) {
      return moment(date).format("dddd, MMMM Do YYYY");
    },

    closeDialog() {
      this.saveDate = null;
      this.$emit("close");
      this.$refs.formAvailability.resetValidation();
    },

    trimMsg(msg) {
      let optionLabel = msg.split("not available<br />");
      if (optionLabel.length == 2) {
        return optionLabel[0] + "not available";
      } else {
        return msg;
      }
    },

    confirmAvailability() {
      if (this.$refs.formAvailability.validate()) {
        let available = {
          id: this.eventId,
        };

        Promise.all([this.removeAvailability({ available })]).then(() => {
          this.closeDialog();
          this.$emit("isModify", { action: "delete" });
        });
      }
    },
  },
};
</script>
