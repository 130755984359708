<template>
  <div>
    <v-dialog
      :value="selectedDate"
      max-width="350"
      persistent
      no-click-animation
    >
      <v-card>
        <v-card-title>
          <v-icon left>mdi-calendar-outline</v-icon>
          <span class="font-weight-bold">Set Date For Availability</span>
        </v-card-title>
        <v-card-text>
          <v-fade-transition>
            <v-form ref="formAvailability" class="mb-n5">
              <v-row>
                <v-col class="pt-0 pb-0">
                  <span class="heading-font font-weight-bold">Please Select:</span>
                  <v-radio-group
                    class="mt-0"
                    dense
                    :rules="[rules.required]"
                    v-model="saveDate"
                  >
                    <v-radio
                      @click="hideNotes()"
                      label="Available"
                      :value="`${date}/available`"
                    ></v-radio>
                    <v-radio
                      @click="showNotes()"
                      label="Not Available"
                      :value="`${date}/notAvailable`"
                    ></v-radio>
                  </v-radio-group>
                  <v-textarea
                    v-if="notesField"
                    name="input-7-4"
                    placeholder="Add a public note for employers (optional)"
                    v-model="notes"
                    solo
                  ></v-textarea>
                </v-col>
              </v-row>
              <small>
                Set this date:
                <span class="font-weight-black">{{ convertDate(date) }}</span>
              </small>
            </v-form>
          </v-fade-transition>

          <div class="mt-9 d-flex gap-7">
            <v-btn
              color="accent"
              class="gradient"
              :loading="status.getting"
              :disabled="status.getting"
              small
              @click="confirmAvailability()"
              >Confirm</v-btn
            >

            <v-btn
              depressed
              small
              :disabled="status.getting"
              @click="closeDialog()"
              >Cancel</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import rules from "@/rules";

export default {
  props: {
    selectedDate: Boolean,
    date: String,
  },

  data() {
    return {
      rules,
      saveDate: null,
      notes: null,
      notesField: false,
    };
  },

  /* -------------------------------------------------------------------------- */
  /*                                  COMPUTED                                  */
  /* -------------------------------------------------------------------------- */
  computed: {
    ...mapState({
      status: (state) => state.availability.status,
    }),
  },
  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  methods: {
    ...mapActions("availability", ["saveAvailability"]),

    convertDate(date) {
      if (date) {
        let choosenDate = date.split("|");

        if (choosenDate.length > 1) {
          return (
            moment(choosenDate[0]).format("dddd, MMMM Do YYYY") +
            " to " +
            moment(choosenDate[1]).format("dddd, MMMM Do YYYY")
          );
        } else {
          return moment(choosenDate[0]).format("dddd, MMMM Do YYYY");
        }
      }
    },

    closeDialog() {
      this.saveDate = null;
      this.notes = null;
      this.notesField = false;
      this.$emit("close");
      this.$refs.formAvailability.resetValidation();
    },

    hideNotes() {
      this.notesField = false;
    },

    showNotes() {
      this.notesField = true;
    },

    confirmAvailability() {
      if (this.$refs.formAvailability.validate()) {
        let eventData = {
          data: this.saveDate,
          notes: this.notes,
        };
        Promise.all([this.saveAvailability({ eventData })]).then(() => {
          this.closeDialog();
          this.$emit("isModify", { action: "store" });
        });
      }
    },
  },
};
</script>
